import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { theme } from 'theme';
import { PageContextType } from 'types';

import Layout from 'components/templates/layout';
import IndustriesAbout, { IndustriesAboutType } from 'components/organisms/industries-about';
import { JobOffersSliderType } from 'components/organisms/job-offers-slider';
import LetsTalkJobOffers from 'components/organisms/lets-talk-job-offers';
import ManagerSection, { ManagerSectionType } from 'components/organisms/manager-section';
import PostsSectionRowSlider, {
  PostsSectionRowSliderType,
} from 'components/organisms/posts-section-row-slider';
import RecruitmentProcessSteps, {
  RecruitmentProcessStepsType,
} from 'components/organisms/recruitment-process-steps';
import TextBannerCardSection, {
  TextBannerCardType,
} from 'components/organisms/text-banner-card-section';
import BannerCard from 'components/molecules/banner-card';
import IndustriesBanner, { IndustriesBannerType } from 'components/molecules/industries-banner';
import IndustriesContactUsBanner, {
  IndustriesContactUsBannerType,
} from 'components/molecules/industries-contact-us-banner';
import LetsTalkBanner, { LetsTalkBannerType } from 'components/molecules/lets-talk-banner';

import { AllJobOfferType } from '../types/job-offers';

type DataProps = {
  commonComponents: {
    commonComponentsData: {
      letsTalkBanner: LetsTalkBannerType;
      otherServicesBlock: TextBannerCardType;
    };
  };
  allJobOfferType: AllJobOfferType;
  offersRecruitmentDetailsPage: {
    template: {
      offersRecruitmentDetailsData: {
        bannerQuaternary: IndustriesBannerType;
        recruitmentDetailsStats: TextBannerCardType;
        recruitmentDescription: IndustriesAboutType;
        recruitmentProcess: RecruitmentProcessStepsType;
        jobOffersSection: JobOffersSliderType;
        knowHowArticles: PostsSectionRowSliderType;
        orderRecruitment: TextBannerCardType;
        recruitmentTeam: ManagerSectionType;
        recruitmentDetailsContactUsBanner: IndustriesContactUsBannerType;
      };
    };
  };
};

const OffersRecruitmentPage = ({
  pageContext,
  data: {
    offersRecruitmentDetailsPage: {
      template: {
        offersRecruitmentDetailsData: {
          bannerQuaternary,
          recruitmentDetailsStats,
          recruitmentDescription,
          jobOffersSection,
          recruitmentProcess,
          knowHowArticles,
          recruitmentDetailsContactUsBanner,
          orderRecruitment,
          recruitmentTeam,
        },
      },
    },
    allJobOfferType,
    commonComponents: {
      commonComponentsData: { letsTalkBanner, otherServicesBlock },
    },
  },
}: PageProps<DataProps, PageContextType>) => (
  <Layout {...{ pageContext }}>
    <IndustriesBanner {...bannerQuaternary} pageContext={pageContext} isWhite />
    <TextBannerCardSection
      {...recruitmentDetailsStats}
      margin={`-${theme.gap.xl} auto 0`}
      padding={`144px 0 ${theme.gap.xl}`}
      bgColor={theme.colors.grayscaleGray0}
      variantHeading="title1"
      variantExtraTitle="title3"
      maxWidth={theme.wrapperXs}
      mobilePadding={`${theme.gap.mdl} ${theme.gap.sm}`}
      textColumn
    >
      {recruitmentDetailsStats.cardList.map((item) => (
        <BannerCard
          {...item}
          key={item.text}
          borderColor={theme.colors.primary50}
          bigTitle
          padding={`0 0 0 ${theme.gap.md}`}
          mobilePadding={`0 0 0 ${theme.gap.sm}`}
        />
      ))}
    </TextBannerCardSection>
    <IndustriesContactUsBanner {...recruitmentDetailsContactUsBanner} />
    <IndustriesAbout
      {...recruitmentDescription}
      padding="0"
      mediumHeading
      mobileMargin="0"
      mobilePadding={`${theme.gap.mdl} ${theme.gap.sm}`}
    />
    <LetsTalkJobOffers
      jobOffersSlider={{
        heading: jobOffersSection.heading,
        subHeading: jobOffersSection.subHeading,
        linkButton: jobOffersSection.linkButton,
        cards: allJobOfferType.edges,
      }}
    />
    <TextBannerCardSection
      {...orderRecruitment}
      padding="0"
      mobilePadding={`${theme.gap.mdl} ${theme.gap.sm} 0`}
    >
      {orderRecruitment
        ? orderRecruitment.cardList.map((item) => <BannerCard {...item} key={item.text} />)
        : null}
    </TextBannerCardSection>
    <ManagerSection
      {...recruitmentTeam}
      margin={`${theme.gap.xl} 0`}
      mobileMargin={`${theme.gap.mdl} 0`}
    />
    <RecruitmentProcessSteps {...recruitmentProcess} />
    <PostsSectionRowSlider
      {...knowHowArticles}
      bgColor={theme.colors.grayscaleGray0}
      padding={`${theme.gap.xl} 0 260px`}
      margin={`${theme.gap.xl} 0 -180px 0`}
      mobilePadding="36px 16px"
      mobileMargin="4px 0 0 0"
    />
    <LetsTalkBanner {...letsTalkBanner} margin="0 auto  0" />
    <TextBannerCardSection
      {...otherServicesBlock}
      variantHeading="title7"
      mobilePadding={`${theme.gap.mdl} ${theme.gap.sm}`}
      textColumn
    >
      {otherServicesBlock && otherServicesBlock.cardList
        ? otherServicesBlock.cardList.map((item) => (
            <BannerCard {...item} maxWidth="340px" margin="0" padding="0" key={item.text} />
          ))
        : null}
    </TextBannerCardSection>
  </Layout>
);

export const query = graphql`
  query OffersRecruitmentDetails($id: String!, $commonComponentsSlug: String!, $unitType: String!) {
    offersRecruitmentDetailsPage: wpPage(id: { eq: $id }) {
      template {
        templateName
        ... on WpTemplate_OffersRecruitmentDetails {
          offersRecruitmentDetailsData {
            bannerQuaternary {
              heading
              text
              image {
                altText
                localFile {
                  url
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            recruitmentDetailsStats {
              heading
              text
              extraTitle: statsHeading
              cardList: statsList {
                heading: text
                text: description
              }
            }
            recruitmentDescription {
              heading
              text
              problemHeading: positionsHeading
              problemsList: positionsList {
                text
              }
            }
            jobOffersSection {
              heading
              subHeading: subTitle
              linkButton {
                label
                link {
                  internal: wpInternal {
                    ... on WpPage {
                      uri
                    }
                  }
                  ariaLabel
                  external {
                    target
                    url
                  }
                }
                icon {
                  name
                }
              }
            }
            recruitmentProcess {
              heading
              subTitle
              text
              processSteps: recruitmentSteps {
                heading
                text
              }
            }
            knowHowArticles {
              heading
              subTitle
              text
              posts: choosenPosts {
                ... on WpKnow_how {
                  id
                  title
                  link
                  date(formatString: "DD.MM.YYYY", locale: "PL")
                  categories {
                    nodes {
                      name
                      slug
                    }
                  }
                  tags {
                    nodes {
                      name
                      uri
                    }
                  }
                  featuredImage {
                    node {
                      ...WpImageFragment
                    }
                  }
                }
              }
            }
            orderRecruitment {
              heading
              text
              cardList: orderRecruitmentList {
                text
                icon {
                  name
                }
              }
            }
            recruitmentDescription {
              heading
              text
              problemHeading: positionsHeading
              problemsList: positionsList {
                text
              }
            }
            recruitmentDetailsContactUsBanner {
              text
              linkButton {
                label
                link {
                  internal: wpInternal {
                    ... on WpPage {
                      uri
                    }
                  }
                  ariaLabel
                  external {
                    target
                    url
                  }
                }
              }
            }
            orderRecruitment {
              heading
              text
              cardList: orderRecruitmentList {
                text
                icon {
                  name
                }
              }
            }
            recruitmentTeam {
              backgroundImage {
                ...WpImageFragment
              }
              businessCards {
                businessCard {
                  text
                  fullName
                  position
                  positionTitle
                  text
                  contactList {
                    type
                    link {
                      ariaLabel
                      wpInternal {
                        ... on WpPage {
                          id
                          uri
                        }
                      }
                      external {
                        target
                        url
                      }
                    }
                  }
                  image {
                    ...WpImageFragment
                  }
                }
              }
            }
          }
        }
      }
    }

    allJobOfferType: allWpJobOffer(
      sort: { fields: [date], order: DESC }
      filter: { offersData: { unit: { eq: $unitType } } }
    ) {
      edges {
        node {
          uri
          title
          date
          offersData {
            consultant
            category
            voivodship
            city
            contracttypes
            salaryfrom
            salaryto
          }
        }
      }
    }
    commonComponents: wpPage(slug: { eq: $commonComponentsSlug }) {
      commonComponentsData {
        letsTalkBanner {
          heading
          description
          linkButton {
            label
            icon {
              name
            }
            link {
              ariaLabel
              internal: wpInternal {
                ... on WpPage {
                  uri
                }
              }
            }
          }
        }
        otherServicesBlock {
          subTitle
          heading
          text
          cardList: servicesList {
            heading
            text
            icon {
              name
            }
            linkButton {
              label
              link {
                ariaLabel
                internal: wpInternal {
                  ... on WpPage {
                    id
                    uri
                  }
                }
                external {
                  target
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default OffersRecruitmentPage;
